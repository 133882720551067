<script setup>
import { normalizeString } from "~/utils/stringHelpers";

const route = useRoute();
const router = useRouter();
const appConfig = useAppConfig();

const { locale, setLocale } = useI18n();
const { t } = useI18n();

const menuItems = ref([]);

const languages = [
  { id: "en", label: 'English', icon: "i-circle-flags-us" },
  { id: "zh", label: '中文', icon: "i-circle-flags-cn" },
];

const currentLocale = ref(languages[0]);

const getLocalizedRoute = (itemRoute) => {
  return locale.value == "zh" ? `/${locale.value}${itemRoute}` : itemRoute;
};

const generateMenuData = (data) => {
  if (data.length > 0) {
    const menuData = (item) => {
      if (!item.can_read) return null;
      const transformed = {
        id: item.menu_item_id?.toString(),
        label: t(normalizeString(item.label)),
        icon: item.icon,
        defaultOpen:
          item.route == "/"
            ? route.path == getLocalizedRoute(item.route)
            : route.path.startsWith(getLocalizedRoute(item.route)),
        to: getLocalizedRoute(item.route),
        tooltip: {
          text: t(normalizeString(item.tooltip_text)),
        },
      };
      if (item.children && item.children.length) {
        transformed.children = item.children
          .filter((child) => child.can_read)
          .map((child) => ({
            label: t(normalizeString(child.label)),
            to: getLocalizedRoute(child.route),
          }));
      }
      return transformed;
    };

    return data
      .sort((a, b) => a.menu_item_id - b.menu_item_id)
      .map(menuData)
      .filter((item) => item !== null);
    // .map(menuData).filter((item) => item !== null);
  }
};

// const menuData = useMenuItems().value
const menuData = useMenuItems();
const sortedMenuData = menuData.value;
menuItems.value = generateMenuData(sortedMenuData);

watch(currentLocale, async (newLocale) => {
  await setLocale(newLocale.id);
  localStorage.setItem("locale", newLocale.id);
  await nextTick();
  menuItems.value = generateMenuData(sortedMenuData);
});

onMounted(() => {
  const savedLocale = localStorage.getItem("locale") || "en";
  const savedLanguage =
    languages.find((lang) => lang.id === savedLocale) || languages[0];
  setLocale(savedLanguage.id);
  currentLocale.value = savedLanguage;
});

const groups = [
  {
    key: "links",
    label: "Go to",
    // commands: links.map(link => ({ ...link, shortcuts: link.tooltip?.shortcuts }))
  },
];

const defaultColors = ref(
  ["green", "teal", "cyan", "sky", "blue", "indigo", "violet"].map((color) => ({
    label: t(color),
    chip: color,
    click: () => (appConfig.ui.primary = color),
  }))
);

const colors = computed(() =>
  defaultColors.value.map((color) => ({
    ...color,
    label: t(color.chip),
    active: appConfig.ui.primary === color.label,
  }))
);

function setHeaderTitle(value) {
  try {
    if (value.includes("/")) {
      if (value.length == 1) {
        useHead({
          title: `${t("multipay")} - ${t("dashboard")}`,
        });
      } else {
        value = value.startsWith("/") ? value.slice(1) : value;

        const routeParts = value.split("/");

        const isChinese = routeParts[0] === "zh";

        const normalizedRoute = isChinese ? routeParts.slice(1) : routeParts;

        // let routeParts = value.split("/").map((part) =>
        //   part
        //     .split("-")
        //     .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        //     .join(" ")
        // );

        // let routeName = routeParts.slice(0, 2).join(" - ");

        let routeName = "";

        if (normalizedRoute.length === 0) {
          routeName = `${t("multipay")} - ${t("dashboard")}`;
        } else if (normalizedRoute.length === 1) {
          routeName =
            t(normalizeString(normalizedRoute[0])).charAt(0).toUpperCase() +
            t(normalizeString(normalizedRoute[0])).slice(1);
        } else if (normalizedRoute.length >= 2) {
          let routeFirstName =
            t(normalizeString(normalizedRoute[0])).charAt(0).toUpperCase() +
            t(normalizeString(normalizedRoute[0])).slice(1);
          let routeSecondName =
            t(normalizeString(normalizedRoute[1])).charAt(0).toUpperCase() +
            t(normalizeString(normalizedRoute[1])).slice(1);
          routeName = `${routeFirstName} - ${routeSecondName}`;
        }

        useHead({
          title: routeName,
        });
      }
    }
  } catch (e) {}
}

setHeaderTitle(route.path);

const currentRoute = computed(() => route.path);

watch(currentRoute, (value) => {
  setHeaderTitle(value);
});
</script>

<template>
  <UDashboardLayout>
    <UDashboardPanel
      :width="250"
      :resizable="{ min: 200, max: 300 }"
      collapsible
    >
      <UDashboardNavbar
        class="!border-transparent"
        :ui="{ left: 'flex-1', right: 'items-center' }"
      >
        <template #left>
          <ClientOnly>
            <USelectMenu v-model="currentLocale" :options="languages">
              <template #leading>
                <Icon v-if="currentLocale.icon" :name="currentLocale.icon" />
              </template>
            </USelectMenu>
          </ClientOnly>
        </template>
        <template #right>
          <ClientOnly>
            <UColorModeToggle />
          </ClientOnly>
        </template>
      </UDashboardNavbar>

      <UDashboardSidebar>
        <template #header>
          <UDashboardSearchButton :label="$t('search')" />
        </template>

        <UDashboardSidebarLinks :links="menuItems" />

        <UDivider />

        <UDashboardSidebarLinks
          :links="[
            {
              label: $t('colors'),
              defaultOpen: false,
              children: colors,
            },
          ]"
          @update:links="(colors) => (defaultColors = colors)"
        />

        <div class="flex-1" />

        <!-- <UDashboardSidebarLinks :links="footerLinks" /> -->

        <UDivider class="sticky bottom-0" />

        <template #footer>
          <!-- ~/components/UserDropdown.vue -->
          <UserDropdown />
        </template>
      </UDashboardSidebar>
    </UDashboardPanel>

    <slot />

    <!-- ~/components/HelpSlideover.vue -->
    <!-- <HelpSlideover /> -->
    <!-- ~/components/NotificationsSlideover.vue -->
    <!-- <NotificationsSlideover /> -->

    <ClientOnly>
      <LazyUDashboardSearch :groups="groups" />
    </ClientOnly>
  </UDashboardLayout>
</template>
