<script setup>
const { data, signOut } = useAuth();
const { t } = useI18n();

const toast = useToast();
// const userInfo = useUser()
const userInfo = ref(data.value.user);

const items = computed(() => [
  [
    {
      slot: "account",
      label: "",
      disabled: true,
    },
  ],
  [
    {
      label: t("sign_out"),
      icon: "i-heroicons-arrow-left-on-rectangle",
      click: async () => {
        await signOut();
      },
    },
  ],
]);
</script>

<template>
  <UDropdown
    mode="hover"
    :items="items"
    :ui="{ width: 'w-full', item: { disabled: 'cursor-text select-text' } }"
    :popper="{ strategy: 'absolute', placement: 'top' }"
    class="w-full"
  >
    <template #default="{ open }">
      <UButton
        color="gray"
        variant="ghost"
        class="w-full"
        :label="userInfo.username"
        :class="[open && 'bg-gray-50 dark:bg-gray-800']"
      >
        <template #leading>
          <UAvatar icon="i-heroicons-user" size="2xs" />
        </template>
        <template #trailing>
          <UIcon name="i-heroicons-ellipsis-vertical" class="w-5 h-5 ml-auto" />
        </template>
      </UButton>
    </template>

    <template #account>
      <div class="text-left">
        <p>{{ $t("signed_in_as") }}</p>
        <p class="truncate font-medium text-gray-900 dark:text-white">
          {{ userInfo.username }}
        </p>
      </div>
    </template>
  </UDropdown>
</template>
